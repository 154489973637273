@import "../variables";

.alerts-section {
  position: fixed;
  top: 5rem;
  right: 5%;
  width: 23rem;
  max-height: 90vh;
  z-index: 2000;

  .alerts-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
