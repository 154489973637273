@import "../variables";

.sign-up-page {
  height: 100%;
  width:100%;
  max-width: initial !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f4f9;
  padding:0 !important;

  .form-container {
    background-color: white;
    max-width: 1024px;
    margin-bottom: 10rem;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .mt--1rem {
      margin-top: -1rem;
    }

    > form {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1rem;
      padding: 1rem 1rem 1.5rem 1rem;

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }

      .text-input {
        &.error {
          label,
          svg {
            color: $error-color-transparent;
          }

          &:hover {
            label,
            svg {
              color: $error-color-transparent;
            }
          }

          &:active,
          &:focus-within {
            label,
            svg {
              color: $error-color;
            }
          }
        }

        label,
        svg {
          color: $border-grey;
        }

        &:hover {
          label,
          svg {
            color: $border-hover-black;
          }
        }

        &:active,
        &:focus-within {
          label,
          svg {
            color: $primary;
          }
        }
      }
    }
  }

  .sign-up-footer {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    align-self: center;
    justify-content: center;
    text-align: center;
    padding-bottom: 1rem;
    margin-top: -0.5rem;

    .link {
      font-weight: bold;
      cursor: pointer;
      margin-left: 0.25rem;
      color: $secondary;
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);

      &:hover {
        color: $secondary-dark;
        text-decoration: underline;
      }
    }
  }
}

.password-requirements {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 1.5rem;
  margin: -0.5rem 0 0.5rem 0;
  gap: 0rem 1.5rem;

  ::marker {
    color: $primary;
  }

  .error {
    color: $error-color;
    &::marker {
      color: $error-color;
    }
  }
}

.sign-up-popup-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
}

@media (max-width: $breakpoint-tablet) {
  .sign-up-page {
    align-items: flex-start;

    .form-container {
      margin: 2rem 0 2rem 0;
      width: 100%;
    }
  }
}
