@import "../variables";

.nav-bar {
  font-size: 1.4rem;

  // 1 z-index above drawer
  z-index: 1201 !important;

  .nav-header {
    font-weight: bold;
    color: $text-color;
    text-decoration: initial;
    letter-spacing: 0.1rem;
  }

  .nav-logo{
    height:52px;
  }
}
