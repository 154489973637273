@import "../variables";

.app {
  height: 100vh;
}

.app-body {
  display: flex;
  height: calc(100% - #{$nav-bar-height});
}

.app-content {
  > *:not(.preloader) {
    max-width: 1400px;
    padding: 0 2rem;
  }
  flex-grow: 1;
}

@media (max-width: $breakpoint-tablet) {
  .app-content {
    > * {
      padding: 0 1rem;
    }
  }
}
