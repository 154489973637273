@import "../../variables";

.supplier-section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 2fr;
  gap: 1rem 1rem;
  grid-template-areas:
    "header header header header header"
    "businessId businessId businessId businessId logo"
    "name name name name logo"
    "address address address address logo"
    "email email phone phone empty";

  h1 {
    grid-area: header;
  }
}

@media(max-width: $breakpoint-tablet){
  .supplier-section {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "header header"
      "businessId businessId"
      "name name"
      "address address"
      "email phone"
      "logo logo";
  }
}

