@import "../../variables";

.customer-section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 2fr;
  grid-template-rows: auto;
  gap: 1rem 1rem;
  grid-template-areas:
    "header header header header header"
    "businessId businessId businessId businessId invoice-no"
    "name name name name issue-date"
    "address address address address payment-details"
    "email email phone phone payment-details";

    h1{
      grid-area: header;
    }

    .separator {
      display: none;
    }
}

@media(max-width: $breakpoint-tablet){
  .customer-section {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "header header"
      "businessId businessId"
      "name name"
      "address address"
      "email phone"
      "separator separator"
      "invoice-no issue-date"
      "payment-details payment-details"
      "payment-details payment-details";
  }

  .separator{
    display: block !important;
    width: 75%;
    border-color: $primary;
    opacity: 75%;
    border-bottom: 0;
  }
}
