@import "../variables";

.invoice-form {
  text-align: center;
  margin: auto;
}

.invoice-grid {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;
  text-align: left;

  h1 {
    padding-bottom: 1rem;
    color: $primary-dark;
    border-bottom: 2px $primary-dark solid;
  }
}

.submit-button {
  padding: 0.5rem 1rem !important;
  margin: 1rem 0rem !important;
}

.notes-field {
  width: 50%;
}

.table-head {
  tr > th {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1rem;
  }
}

.total-section {
  border-top: 1px solid $black;
}

@media (max-width: $breakpoint-tablet) {
  .notes-field {
    width: 100%;
  }
}
