@import "../variables";

$loading-color: #888888;

@keyframes upAndDownLoading {
  0% {
    transform: translateY(0);
  }
  15% {
    transform: translateY(-2rem);
  }
  30% {
    transform: translateY(0);
  }
}

.preloader {
  display: flex;
  background-color: #ebebeb;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  transition: 200ms all ease-in;

  &.active {
    opacity: 1;
    display: flex;
    .square {
      opacity: 1;
      animation-name: upAndDownLoading;
    }
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .square {
    opacity: 0;
    width: 3rem;
    height: 3rem;
    border-radius: 1rem;
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.12);
    background-color: $secondary;
    animation-duration: 2s;
    animation-timing-function: ease-in;
    animation-iteration-count: infinite;
    transition: 200ms all ease-in;

    + .square {
      margin-left: 1rem;
    }
  }

  .square:nth-child(1) {
    animation-delay: 0ms;
  }

  .square:nth-child(2) {
    animation-delay: 200ms;
  }

  .square:nth-child(3) {
    animation-delay: 400ms;
  }

  .square:nth-child(4) {
    animation-delay: 600ms;
  }
}
